// AuthService.js
import axios
    from 'axios';


const apiUrls = 'https://app.maskedautoinstall.com/api';
export default {

    async loginForm(username, password) {
        try {
            if (username && password) {
                const formData = new FormData();
                formData.append('username', username);
                formData.append('password', password);
                const response = await axios.post(apiUrls + '/login', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.data.status === false) {
                    return response.data.message;
                } else {
                    const token = response.data.token;
                    localStorage.setItem('authToken', token);
                    let data = {
                        username: response.data.username,
                        balance: response.data.balance,
                    };
                    console.log(data)
                    return data
                }
            }

        } catch (error) {
            return {error: 'Login Form Error'};
        }
    },

    async checkLoginStatus() {
        try {
            const token = localStorage.getItem('authToken');
            if (token) {
                const response = await axios.get(apiUrls + '/checkLogin', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                return {
                    username: response.data[0].username,
                    balance: response.data[0].balance,
                };
            }


        } catch (error) {
            localStorage.removeItem('authToken');
            return error;
        }
    },
    logout() {
        try {
            localStorage.removeItem('authToken');
        } catch (error) {
            console.log(error)
        }
    },
    getToken() {
        return localStorage.getItem('authToken');
    },

    isAuthenticated() {
        return !!this.getToken();
    },
    apiUrl() {
        return apiUrls;
    }
};